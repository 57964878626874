import * as React from "react";
import { ToggleContainerProps } from "./typings";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { ToggleAllContainer } from "./views/ToggleAllContainer";
import { ToggleSingleContainer } from "./views/ToggleSingleContainer";

enum ToggleContainerViews {
  TOGGLE = "toggle",
  EXPAND_COLLAPSE_SUBREGIONS = "expand-collapse-subregions",
}

interface ComponentsViews {
  [ToggleContainerViews.TOGGLE]: (props: ToggleContainerProps) => React.JSX.Element | null;
  [ToggleContainerViews.EXPAND_COLLAPSE_SUBREGIONS]: (props: ToggleContainerProps) => React.JSX.Element | null;
}

export const ToggleContainer = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: ToggleContainerProps) => {
    const { templateComponent } = props;

    /* istanbul ignore next */
    if (!templateComponent) {
      return null;
    }

    const {
      config: { view },
    } = templateComponent;

    const getComponentFromView = (view: string) => {
      const componentsViews: ComponentsViews = {
        [ToggleContainerViews.TOGGLE]: ToggleSingleContainer,
        [ToggleContainerViews.EXPAND_COLLAPSE_SUBREGIONS]: ToggleAllContainer,
      };

      return componentsViews[view as keyof ComponentsViews];
    };

    const ViewComponent = getComponentFromView(view!);

    return <ViewComponent {...props} />;
  })
);

export default ToggleContainer;
